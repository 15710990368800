.dropdown-submenu.dropright .dropdown-menu {
  margin-left: 1px;
}

.dropdown-submenu.dropleft .dropdown-menu {
  margin-right: 1px;
}

[x-placement^="bottom-"] .dropdown-submenu .dropdown-menu,
.navbar .dropdown-submenu .dropdown-menu {
  bottom: auto;
  margin-top: calc(-0.5rem - 1px);
}

[x-placement^="top-"] .dropdown-submenu .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-bottom: calc(-0.5rem - 1px);
}

.dropdown-submenu.dropright > .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-submenu.dropright > .dropdown-toggle::after {
  margin-right: -12px;
}
